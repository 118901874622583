import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export async function createCheckoutSession(uid, price, mode, billing_address_collection) 
{
  const billingAddressCollection = billing_address_collection ? billing_address_collection : "auto"
  
  const checkoutSessionRef = await addDoc(
    collection(firestore, `users/${uid}/checkout_sessions`),
    {
      mode,
      allow_promotion_codes: true,
      billing_address_collection: billingAddressCollection,
      success_url: `${window.location.origin}/Sucesso?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: window.location.origin,
      payment_method_options: {
        card: { installments: { enabled: true } },
      },
      line_items: [
        {
          price: price,
          adjustable_quantity: { enabled: true },
          quantity: 1,
        },
      ],
    }
  );

  onSnapshot(checkoutSessionRef, async (snap) => {
    const { error, url } = snap.data();
    if (error) {
      alert(`Ocorreu um erro: ${error.message}`);
    }
    if (url) {
      // Adiciona o script do pixel do Facebook à página de checkout
      const facebookPixelScript = document.createElement('script');
      facebookPixelScript.src = 'https://www.facebook.com/tr?id=277620761886211&ev=PageView&noscript=1';
      facebookPixelScript.async = true;
      document.head.appendChild(facebookPixelScript);
      
      window.location.assign(url);
    }
  });
}
