import React, { useState, useEffect } from "react";
import HeaderPurchase from "../../components/HeaderPurchase";
import { onAuthStateChanged } from "firebase/auth";
import {
  Content,
  Footer,
  ViewTextInput,
  ViewButton,
  ViewCheckBox,
  ViewText,
  ViewHelper,
  ViewTitle,
  VerticalScroll,
} from "./style";
import THEME from "../../config/theme";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import {
  emailValidator,
  passwordValidator,
  nameValidator,
  cellphoneValidator,
  cpfValidator,
} from "../../utils";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { setDoc, doc, query, where, collection, getDocs, addDoc, onSnapshot, getDoc } from "firebase/firestore";
import { auth, firestore } from "../../services/firebase";
import { AlertBox } from "../../components/AlertBox";
import { HelperText, Provider} from "react-native-paper";
import { Title } from "../../config/theme/globalStyles";
import TouchableText from "../../components/TouchableText";
import { TextInputMask } from "react-native-masked-text";
import { SafeAreaView, View, ActivityIndicator, Pressable} from "react-native";
import { getFunctions, httpsCallable } from "firebase/functions";
import { StandardText } from "../../config/theme/globalStyles";

const functions = getFunctions();

export function SignUp({ navigation, route }) {
  const { purchaseType, mode, priceId, productId, billing_address_collection } = route.params;
  const [name, setName] = useState({ value: "", error: "" });

  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: "",
  });
  const [cellphone, setCellPhone] = useState({ value: "", error: "" });
  const [cpf, setCpf] = useState({ value: "", error: "" });
  const [loading, setLoading] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(true);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessege] = useState(null);
  const [text, setText] = useState("Aguarde! Iniciando o checkout para o pagamento.");
  const [priceIdOption, setPriceIdOption] = useState("");


  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setTitle(title);
    setMessege(message);
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const validation = () => {
    let error = false
    const nameError = nameValidator(name.value);
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    const confirmPasswordError = passwordValidator(confirmPassword.value);
    const cellphoneError = cellphoneValidator(cellphone.value);
    const cpfError = cpfValidator(cpf.value);

    if (
      emailError ||
      passwordError ||
      nameError ||
      cellphoneError ||
      cpfError
    ) {
      let error = true
      setName({ ...name, error: nameError });
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      setConfirmPassword({ ...confirmPassword, error: confirmPasswordError });
      setCellPhone({ ...cellphone, error: cellphoneError });
      setCpf({ ...cpf, error: cpfError });
      setLoading(false);
      return error;
    }
    return error;
  };

  const getRawValue = (value) => {
    return value.replace(/\D/g, "").trim();
  };

  const checkout = async (purchase, userId) => {

    if (purchase === "PLAN") {
      if (productId) {
        await findPriceId(userId)
        return
      }

    } else if (purchase === "COURSE") {
      const priceIdToBeUsed = productId ? priceIdOption : priceId
      return createCheckoutSession(userId, priceIdToBeUsed, mode, billing_address_collection);
    }
  };

  const createCheckoutSession = async (uid, price, mode) => {
    const checkoutSessionRef = await addDoc(collection(firestore, `users/${uid}/checkout_sessions`), {
      mode,
      allow_promotion_codes: true,
      billing_address_collection: billing_address_collection ? billing_address_collection : 'auto',
      success_url: `${window.location.origin}/Sucesso?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: window.location.origin,
      payment_method_options: {
        card: { installments: { enabled: true } },
      },
      line_items: [
        {
          price: price,
          adjustable_quantity: { enabled: true },
          quantity: 1,
        },
      ],
    });

    onSnapshot(checkoutSessionRef, async (snap) => {
      const { error, url } = snap.data();
      if (error) {
        alert(`Ocorreu um erro: ${error.message}`);
      }
      if (url) {
        // Adiciona o script do pixel do Facebook à página de checkout
        const facebookPixelScript = document.createElement('script');
        facebookPixelScript.src = 'https://www.facebook.com/tr?id=277620761886211&ev=PageView&noscript=1';
        facebookPixelScript.async = true;
        document.head.appendChild(facebookPixelScript);
        
        window.location.assign(url)
        window.history.replaceState(null, '', '/');
      }
    });
  };

  const createUserAndSession = (userId) => {
    const usersCollectionRef = doc(firestore, "users", userId);
    const createUser = async () => {
      await setDoc(usersCollectionRef, {
        Nome_Completo: name.value,
        Email: email.value,
        Celular: cellphone.value,
        CPF: getRawValue(cpf.value),
      });
    };

    const sendWelcomeEmail = async () => {
      const q = query(collection(firestore, "users"), where("isAdmin", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let adminInfo = doc.data();

        if (adminInfo.planType === "growth") {
          let myData = {
            user: {
              Nome_Completo: name.value,
              Email: email.value,
              id: userId,
            },
            subject: `Bem-vindo ao App ${adminInfo.appInformation.appName}`,
            html: `
            <body style="font-family: 'Arial', sans-serif;font-size: 1.1rem; line-height: 1.5; color: #000000; margin: 0; padding: 0">
            <h1 style="font-size: 1.8rem; font-weight: bold; text-align: center; margin-bottom: 2rem">Olá, seja muito bem-vindo(a) ao app ${adminInfo.appInformation.appName}!</h1>
            <p>Estamos muito animados em ter você por aqui!</p>
            <p>Sua conta agora está pronta e você pode fazer <b><i>login</i></b> com o seu email e senha cadastrados, visitando:</p>
            <p><a href="${adminInfo.appInformation.loginUrl}"><b>${adminInfo.appInformation.loginUrl}</b></a></p>
            <p>Seu email de cadastro é: <i>${email.value}</i></p>
            <p>Após você fazer o login, poderá realizar <i>compras pelo aplicativo</i> acessando: <a href="${adminInfo.appInformation.webUrl}"><i>${adminInfo.appInformation.webUrl}</i></a></p>
            ${adminInfo.appInformation.IOSUrl && adminInfo.appInformation.androidUrl ? `
              <p>Para fazer o <b><u>Download do Aplicativo Mobile</u></b>, use as mesmas informações de login e busque por <b>${adminInfo.appInformation.appName}</b> na sua loja de aplicativos favorita ou clique em um dos botões abaixo:</p>
              <div style="margin: 2rem 0; display: flex; justify-content: center; align-items: center">
                <a href="${adminInfo.appInformation.IOSUrl}" target="_blank">
                  <img style="height: 3rem; margin: 0 1rem" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Available_on_the_App_Store_%28black%29.png/320px-Available_on_the_App_Store_%28black%29.png" alt="Download on the App Store">
                </a>
                <a href="${adminInfo.appInformation.androidUrl}" target="_blank">
                  <img style="height: 3rem; margin: 0 1rem" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/320px-Google_Play_Store_badge_EN.svg.png" alt="Download on Google Play">
                </a>
              </div>`
                :
                `<div></div>`
              }
            <p><b>${adminInfo.Nome_Completo}</b></p>
            <footer style="text-align: center; padding: 1rem; margin-top: 2rem; color: #444; font-size: 0.8rem">
              Este é um e-mail automático.
            </footer>
          </body>`,
          };

          const sendEmailToFirestore = async () => {
            try {
              const mailTrigger = httpsCallable(functions, "mailTrigger");

              if (adminInfo.appInformation) {
                await mailTrigger(myData);
              } else {
                console.log("does not have appInformation to send e-mail")
              }
            } catch (error) {
              console.log("Failed to trigger email", error);
            }
          };
          sendEmailToFirestore()
        }
      });
    };
    createUser()
      .then(() => {
        sendWelcomeEmail()
          .then(() => {
            checkout(purchaseType, userId);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onSignUpPressed = () => {
    if (validation() == false) {
      if (
        email.value &&
        password.value &&
        name.value &&
        cellphone.value &&
        cpf.value
      ) {
        if (password.value !== confirmPassword.value) {
          showAlert("Erro", "As senhas não correspondem.");
        } else {
          setLoading(true);
          createUserWithEmailAndPassword(auth, email.value, password.value)
            .then((currentUser) => {
              // Lida com o resultado, se necessário
              createUserAndSession(currentUser.user.uid)
            })
            .catch((error) => {
              setLoading(false);
              console.error("erro:", error);
              switch (error.code) {
                case "auth/email-already-in-use":
                  showAlert(
                    "Erro:",
                    "Este email já está cadastrado. Por favor, faça o seu login."
                  );
                  break;
                case "auth/weak-password":
                  showAlert(
                    "Erro:",
                    "Senha deve conter pelo menos 6 caracteres."
                  );
                  break;
                case "auth/invalid-email":
                  showAlert("Erro:", "Email inválido.");
                  break;
                case "auth/operation-not-allowed":
                  showAlert("Erro:", "Problemas ao cadastrar o usuário.");
                  break;
              }
            });
        }
      }
    } else {
      setLoading(false);
    }
  };

  const findPriceId = async (userId) => {

    const plansCollectionRef = collection(firestore, 'plans');

    // Query for documents where the value of the "monthly" property matches the productId
    const monthlyQ = query(plansCollectionRef, where('productIds.monthly', '==', productId));
    const monthlyQuerySnapshot = await getDocs(monthlyQ);

    // Query for documents where the value of the "quarterly" property matches the productId
    const quarterlyQ = query(plansCollectionRef, where('productIds.quarterly', '==', productId));
    const quarterlyQuerySnapshot = await getDocs(quarterlyQ);

    // Query for documents where the value of the "semiannual" property matches the productId
    const semiannualyQ = query(plansCollectionRef, where('productIds.semiannual', '==', productId));
    const semiannualyQQuerySnapshot = await getDocs(semiannualyQ);

    // Query for documents where the value of the "yearly" property matches the productId
    const yearlyQ = query(plansCollectionRef, where('productIds.yearly', '==', productId));
    const yearlyQuerySnapshot = await getDocs(yearlyQ);

    // Merge the query results
    const querySnapshot = [...monthlyQuerySnapshot.docs, ...quarterlyQuerySnapshot.docs, ...semiannualyQQuerySnapshot.docs, ...yearlyQuerySnapshot.docs];

    let priceIdWithoutTrial = '';
    let priceIdWithTrial = '';

    querySnapshot.forEach((doc) => {
      const planData = doc.data();
      const productIds = planData.productIds;

      if (productIds?.monthly === productId) {

        priceIdWithoutTrial = planData?.monthlyPriceId;
        priceIdWithTrial = planData?.monthlyPriceIdWithTrial ? planData?.monthlyPriceIdWithTrial : undefined;

      } else if (productIds?.quarterly === productId) {
        //o priceIdWithoutTrial sempre existe. Aqui ele verifica se existe o parcelado, pega preferencialmente o parcelado e se não, ele tem que pegar o à vista, pois podem existir
        //clientes que não querem oferecer planos trimestrais, semestrais e anuais de forma parcelada.
        priceIdWithoutTrial = planData?.quarterlyPriceId ? planData?.quarterlyPriceId : planData?.quarterlyAtSightPriceId;
        //aqui verificamos se o cliente oferece teste grátis, pois podem existir clientes que não oferecem.
        const hasTrial = planData?.quarterlyPriceIdWithTrial || planData?.quarterlyAtSightPriceIdWithTrial ? true : false;
        //se o cliente oferece teste grátis e tem o parcelado, preferencialmente o código pega o parcelado porque o à vista está no upsell. Se o cliente nao tem o parcelado, ele pega o à vista. 
        //se o cliente não oferece teste grátis, definimos o priceIdWithTrial como undefined
        priceIdWithTrial = hasTrial === true ? (planData?.quarterlyPriceIdWithTrial ? planData?.quarterlyPriceIdWithTrial : planData?.quarterlyAtSightPriceIdWithTrial) : undefined
      } else if (productIds?.semiannual === productId) {

        priceIdWithoutTrial = planData?.semiannualPriceId ? planData?.semiannualPriceId : planData?.semiannualAtSightPriceId;
        const hasTrial = planData?.semiannualPriceIdWithTrial || planData?.semiannualAtSightPriceIdWithTrial ? true : false;
        priceIdWithTrial = hasTrial === true ? (planData?.semiannualPriceIdWithTrial ? planData?.semiannualPriceIdWithTrial : planData?.semiannualAtSightPriceIdWithTrial) : undefined

      } else if (productIds?.yearly === productId) {

        priceIdWithoutTrial = planData?.yearlyPriceId ? planData?.yearlyPriceId : planData?.yearlyAtSightPriceId;
        const hasTrial = planData?.yearlyPriceIdWithTrial || planData?.yearlyAtSightPriceIdWithTrial ? true : false;
        priceIdWithTrial = hasTrial === true ? (planData?.yearlyPriceIdWithTrial ? planData?.yearlyPriceIdWithTrial : planData?.yearlyAtSightPriceIdWithTrial) : undefined

      }

    });

    if (!priceIdWithoutTrial) {
      throw new Error('Price ID not found for the given product ID');
    }

    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const firstPlanPurchase = docSnap.data()?.firstPlanPurchase
      const priceIdOption = firstPlanPurchase != false && priceIdWithTrial != null ? priceIdWithTrial : priceIdWithoutTrial
      return await createCheckoutSession(userId, priceIdOption, mode, billing_address_collection);
    } else {
      const priceIdOption = priceIdWithTrial != null ? priceIdWithTrial : priceId
      setPriceIdOption(priceIdOption)
    }
  };

  useEffect(() => {
    // Signout de qualquer usuário anônimo existente
    const signOutAnonymousUser = async () => {
      const user = auth.currentUser;
      if (user && user?.isAnonymous) {
        try {
          await signOut(auth);
        } catch (error) {
          console.error('Erro ao desconectar usuário anônimo:', error);
        }
      }
    };

    // Verifique se há um usuário autenticado
    const checkAuthenticatedUser = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (!user || user?.isAnonymous) {
          setLoadingCheckout(false);
        } else if (user) {
          const userRegistrationDate = user.metadata.creationTime;

          // Verifique se o usuário foi cadastrado recentemente (por exemplo, nos últimos X minutos)
          const registrationTimeThreshold = 5 * 1000; // 5 segundos em milissegundos

          const docRef = doc(firestore, "users", user?.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.data().plan) {
            setText("Ops! Você já possui um plano ativo! Para adquirir esse novo plano, você precisa cancelar o que já possui ou criar um novo login.")
          } else if (userRegistrationDate && Date.now() - new Date(userRegistrationDate).getTime() < registrationTimeThreshold) {
            // O usuário foi cadastrado recentemente, não chame findPriceId
            return
          } else {
            // O usuário não foi cadastrado recentemente, chame findPriceId
            await findPriceId(user?.uid)

          }
        }
      });
    };

    // Execute as funções necessárias
    signOutAnonymousUser();
    checkAuthenticatedUser();
  }, []);

  if (loadingCheckout) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: THEME.COLORS.BACKGROUND_ABOUT }}>
        <ActivityIndicator color={THEME.COLORS.PRIMARY_900} size="large" />
        <StandardText color={THEME.COLORS.TEXT_ABOUT} padding="1rem">{text}</StandardText>
      </View>
    );
  }

  return (
    <SafeAreaView style={{ flexGrow: 1, backgroundColor: THEME.COLORS.BACKGROUND_ABOUT }}>
      <Provider>
        <VerticalScroll>
          <HeaderPurchase signUp={true} />
          <ViewTitle>
            <Title
              fontSize={THEME.FONTSIZE.MEDIUM}
              textAlign="left"
              padding="1rem 0rem 0rem 1rem"
            >
              Criar uma conta
            </Title>
          </ViewTitle>
          <Content>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  label="Nome Completo"
                  placeholder="Digite seu nome completo"
                  returnKeyType="next"
                  value={name.value}
                  onChangeText={(text) => setName({ value: text, error: "" })}
                  error={!!name.error}
                />
              </ViewText>
              <ViewHelper>
                <HelperText type="error" visible={name.error}>
                  {name.error}
                </HelperText>
              </ViewHelper>
            </ViewTextInput>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  label="Email"
                  placeholder="Digite seu email"
                  returnKeyType="next"
                  value={email.value}
                  onChangeText={(text) => setEmail({ value: text, error: "" })}
                  error={!!email.error}
                  autoCapitalize="none"
                  autoCompleteType="email"
                  textContentType="emailAddress"
                  keyboardType="email-address"
                />
              </ViewText>
              <ViewHelper>
                <HelperText type="error" visible={email.error}>
                  {email.error}
                </HelperText>
              </ViewHelper>
            </ViewTextInput>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  label="Celular"
                  placeholder="(DDD) 99999-9999"
                  returnKeyType="next"
                  value={cellphone.value}
                  onChangeText={(text) => setCellPhone({ value: text, error: "" })}
                  error={!!cellphone.error}
                  keyboardType="phone-pad"
                />
              </ViewText>
              <ViewHelper>
                <HelperText type="error" visible={cellphone.error}>
                  {cellphone.error}
                </HelperText>
              </ViewHelper>
            </ViewTextInput>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  label="CPF"
                  placeholder="___.___.___.__"
                  returnKeyType="done"
                  value={cpf.value}
                  onChangeText={(text) => setCpf({ value: text, error: "" })}
                  error={!!cpf.error}
                  keyboardType="numeric"
                  render={(props) => <TextInputMask {...props} type={"cpf"} />}
                />
              </ViewText>
              <ViewHelper>
                <HelperText type="error" visible={cpf.error}>
                  {cpf.error}
                </HelperText>
              </ViewHelper>
            </ViewTextInput>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  label="Senha"
                  placeholder="Digite uma senha"
                  returnKeyType="next"
                  value={password.value}
                  onChangeText={(text) =>
                    setPassword({ value: text, error: "" })
                  }
                  error={!!password.error}
                  secureTextEntry
                />
              </ViewText>
              <ViewHelper>
                <HelperText type="error" visible={password.error}>
                  {password.error}
                </HelperText>
              </ViewHelper>
            </ViewTextInput>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  label="Confirmar Senha"
                  placeholder="Digite novamente a senha"
                  returnKeyType="next"
                  value={confirmPassword.value}
                  onChangeText={(text) =>
                    setConfirmPassword({ value: text, error: "" })
                  }
                  error={!!confirmPassword.error}
                  secureTextEntry
                />
              </ViewText>
              <ViewHelper>
                <HelperText type="error" visible={confirmPassword.error}>
                  {confirmPassword.error}
                </HelperText>
              </ViewHelper>
            </ViewTextInput>
          </Content>
          <ViewCheckBox>
            <Pressable>
              <TouchableText
                onPress={() => navigation.navigate("TermosdeUso")}
                title={"Ao prosseguir para o pagamento, você está concordando com os Termos de Uso"}
                textDecoration="underline"
                color={THEME.COLORS.TEXT_ABOUT}
                fontFamily={THEME.FONTFAMILY.MEDIUM}
                fontSize={THEME.FONTSIZE.EXTRASMALL}
              ></TouchableText>
            </Pressable>
          </ViewCheckBox>
          <ViewButton>
            <Button
              title={"Ir para pagamento"}
              isLoading={loading}
              onPress={onSignUpPressed}
            ></Button>
          </ViewButton>
          <Footer userIsAdmin={false}>
            <TouchableText
              textDecoration="underline"
              onPress={() => navigation.navigate("Login")}
              title={"Já possuo uma conta"}
              color={THEME.COLORS.PRIMARY_900}
            ></TouchableText>
          </Footer>
          {visibleAlert && (
            <AlertBox
              title={title}
              message={message}
              visible={visibleAlert}
              onClose={hideAlert}
            ></AlertBox>
          )}
        </VerticalScroll>
      </Provider>
    </SafeAreaView>
  );
}